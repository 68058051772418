.card {
  border-radius: 4px;
  box-shadow: 0px 4px 25px #f0fafc;
  padding: 20px;
  margin: 25px 0;
}

.card__header-container {
  align-items: center;
  border-bottom: 1px solid var(--anti-flash-white);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card__header {
  border-bottom: 1px solid var(--anti-flash-white);
  color: var(--pal-blue);
  padding-bottom: 5px;
}

.card__header--stats {
  padding-bottom: 10px;
}

.card__row {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: space-between;
}

.card__row--stats {
  width: 60%;
}

.card__row--header {
  height: 30px;
  margin-top: 10px;
}

.card__row--last {
  margin: 0 -2.5% 0 -2.5%;
}

.card__row-input {
  width: 70px;
}

.card__input-container {
  margin-top: 20px;
}

.card__input-container--no-label {
  margin-top: 30px;
}

.card__link {
  border-bottom: 1px solid var(--sonic-silver);
  color: var(--black-coral);
  display: block;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  text-decoration: none;
  width: 100%;
}

.card__link--header {
  align-items: center;
  color: var(--pal-blue);
  display: flex;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}
