.top-nav {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.top-nav-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 500px;
  padding: 10px 15px;
}

.top-nav__logo {
  height: 25px;
}

.bottom-nav {
  background-color: #ffffff;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.bottom-nav-content {
  margin: 0 auto;
  max-width: 550px;
}
