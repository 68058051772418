.workouts {
  text-align: center;
}

.stretching-person {
  margin: 150px auto 20px;
  width: 100px;
}

.stretching-person img {
  width: 100px;
}

.month-select {
  font-size: 26px;
  width: auto;
}

.exercise-select {
  font-size: 20px;
  margin-top: 20px;
  width: auto;
}
