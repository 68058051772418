.react-select__control {
  border: none !important;
  border-radius: 0 !important;
  font-size: 20px;
  border-bottom: 1px solid var(--sonic-silver) !important;
}

.react-select__control--is-focused {
  box-shadow: none !important;
  border-bottom: 1px solid var(--pal-blue) !important;
}

.react-select__value-container {
  padding: 0 !important;
}

.react-select__single-value {
  color: var(--pal-blue) !important;
  margin: 0 !important;
}

.react-select__menu-list {
  border: none !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.react-select__option--is-focused {
  /* can update the option that is in focus */
  /* background-color: var(--grey-blue) !important; */
}

.react-select__option--is-selected {
  background-color: var(--pal-blue) !important;
}
