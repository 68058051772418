body {
  color: var(--black-coral);
  font-family: 'Poppins', 'sans-serif';
  font-weight: 500;
  line-height: 24px;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 20px;
}

p {
  color: var(--grey-blue);
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
}

label {
  color: var(--sonic-silver);
  font-size: 12px;
  font-weight: 400;
}

input,
select {
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--sonic-silver);
  color: var(--black-coral);
  display: block;
  font-size: 16px;
  padding: 0;
  width: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.light {
  font-weight: 400;
}
