.cta-button {
  margin: 40px 0;
  text-transform: none;
}

.btn-wide {
  width: 100%;
}

.btn-link {
  border: 0;
  background-color: #fff;
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
}

.btn-link:focus {
  outline: none;
}

.link-small {
  font-weight: 500;
  color: var(--black-coral);
  text-decoration: none;
}

.link-small:visited {
  color: var(--black-coral);
}
